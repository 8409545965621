/* You can add global styles to this file, and also import other style files */
@import "node_modules/bootstrap/dist/css/bootstrap.min.css";
@import 'node_modules/@bosch/frontend.kit-npm/dist/frontend-kit.css';

a:hover {
  cursor: pointer;
}

main {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.a-list--dot li:before,
ul > li:before {
  background-color: transparent !important;
}
